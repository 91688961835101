<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <filter-formalities ref="FilterFormalities" @update="totalRecords = $event"/>
            </b-card>
        </b-col>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-row>
                        <b-col cols="12" md="9"
                               class="d-flex align-items-center justify-content-start mb-1"
                        >
                            <label>Mostrar</label>
                            <v-select
                                v-model="pageLength"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="['3','5','10','20', '50', '100']"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="changeLength"
                            />
                            <create-formality-button/>
                        </b-col>
                    </b-row>

                    <table-formalities ref="tableFormalities"/>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                cols="12"
                                sm="6"
                            >
                                <span class="text-muted">Mostrant {{ pageLength }} de {{ totalRecords }} tràmits</span>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                cols="12"
                                sm="6"
                            >
                                <b-pagination
                                    :per-page="pageLength"
                                    :total-rows="totalRecords"
                                    :value="1"
                                    align="right"
                                    class="mt-1 mb-0"
                                    first-number
                                    last-number
                                    next-class="next-item"
                                    prev-class="prev-item"
                                    @input="changePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BPagination, BOverlay } from 'bootstrap-vue'
import TableFormalities from "@/views/labor/formalities/list/components/tableFormalities"
import CreateFormalityButton from "@/views/labor/formalities/list/components/createFormalityButton"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import vSelect from "vue-select"
import FilterFormalities from "@/views/labor/formalities/list/components/filterFormalitiesView"

const {mapFields} = createHelpers({
    getterType: 'formalities/getField',
    mutationType: 'formalities/updateField',
})

export default {
    name: "ListFormalities",
    components: {
        BRow,
        BCol,
        BCard,
        BPagination,
        BOverlay,

        TableFormalities,
        CreateFormalityButton,
        FilterFormalities,
        vSelect
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: '',
            showLoading: false,


            CatalanLocale
        }
    },
    mounted() {
        this.$refs.FilterFormalities.getFormalities()
    },
    methods: {
        changePage(page) {
            this.page = page
            this.$refs.FilterFormalities.page = page
            this.$refs.FilterFormalities.getFormalities()
        },
        changeLength(length) {
            this.$refs.FilterFormalities.pageLength = length
            this.$refs.FilterFormalities.getFormalities()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>